import axiosPlugin from "../../plugins/axios";
import toastPlugin from "../../plugins/messages";
const axios = axiosPlugin._axios;
const toast = toastPlugin.toast;
const getMessage = toastPlugin.getMessage;

const backFromSentPrestation = async campaignAnalyseId => {
	try {
		const response = await axios.delete(`/campaign-analysis/${campaignAnalyseId}/back-from-sent-provider`, {
			showLoader: false
		});
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les communes" + getMessage(error.response.status)
		);
		throw error;
	}
};

const deleteEurofinsPrestation = async(campaignAnalyseId) => {
	try {
		const response = await axios.delete(`/campaign-analysis/${campaignAnalyseId}/remove-eurofins-prestations`);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de mettre à jour l'accès au client sur la campagne" + getMessage(error.response.status)
		);
		throw error;
	}
};

export default {
	backFromSentPrestation,
	deleteEurofinsPrestation
};
