import {api} from "../../api";

export default {
	namespaced: true,
	strict: false,

	state: {
		projects: [],
		organizations: false,
		customerOrganizations: false,
		sites: false,
		customerSite: false,
		pageLoaded: [],
		projectPage: false,

		filterName: false,
		filterOrga: false,
		filterSite: false,
		filterCampaign: false,
		filterType: false,
		filterReference: false
	},

	mutations: {
		SET_PROJECTS(state, payload){
			const {projects, offset, limit} = payload;
			let page = (offset / limit) + 1;
			let normalLengthPage = (page - 1) * limit;

			if(page !== 1 && normalLengthPage >= state.projects.length){
				let diffencePage = normalLengthPage - state.projects.length;
				while(diffencePage > 0){
					state.projects.push([]);
					diffencePage--;
				}
				projects.forEach(campaign => {
					state.projects.push(campaign);
				});
			}

			else {
				if(Math.max(state.pageLoaded) !== page - 1){
					let max = offset + limit;
					let index = 0;
					for(let i = offset; i < max; i++){
						state.projects[i] = projects[index];
						index++;
					}
				}
				else {
					projects.forEach(campaign => {
						state.projects.push(campaign);
					});
				}
			}
		},
		CLEAR_PROJECTS(state){
			state.projects = [];
		},
		SET_FILTERNAME(state, value){
			if(value === "" || value === null)state.filterName = false;
			else state.filterName = value;
		},
		SET_FILTERORGA(state, value){
			if(value === "" || value === null)state.filterOrga = false;
			else state.filterOrga = value;
		},
		SET_FILTERSITE(state, value){
			if(value === "" || value === null)state.filterSite = false;
			else state.filterSite = value;
		},
		SET_FILTERCAMPAIGN(state, value){
			if(value === "" || value === null)state.filterCampaign = false;
			else state.filterCampaign = value;
		},
		SET_FILTERTYPE(state, value){
			if(value === "" || value === null)state.filterType = false;
			else state.filterType = value;
		},
		SET_FILTERREFERENCE(state, value){
			if(value === "" || value === null)state.filterReference = false;
			else state.filterReference = value;
		},
		SET_PROJECT(state, value){
			let index = state.projects.findIndex(e => e.id = value.id);
			state.projects[index] = value;
			state.projects = [...state.projects];
		},
		SET_PAGELOADED(state){
			state.pageLoaded = [];
			state.pageLoaded.push(1);
		},
		UPDATE_PAGELOADED(state, number){
			state.pageLoaded.push(number);
		},
		SET_PROJECT_FILTER(state, projects){
			state.projects = projects;
		},
		SET_PROJECT_PAGE(state, value){
			state.projectPage = value;
		},
		SET_CUSTOMER_ORGANIZATIONS(state, value){
			state.customerOrganizations = value;
		},
		SET_SITES(state, value){
			state.sites = value;
		}
	},

	getters: {
		projects(state){
			return state.projects;
		},
		organizations(state){
			return state.organizations || [];
		},
		customerOrganizations(state){
			return state.customerOrganizations || [];
		},
		customersite(state){
			return state.customersite || [];
		},

		filterName(state){
			return state.filterName || "";
		},
		filterOrga(state){
			return state.filterOrga || "";
		},
		filterSite(state){
			return state.filterSite || "";
		},
		filterCampaign(state){
			return state.filterCampaign || "";
		},
		filterType(state){
			return state.filterType || "";
		},
		filterReference(state){
			return state.filterReference || "";
		},
		pageLoaded(state){
			return state.pageLoaded;
		},
		projectPage(state){
			return state.projectPage;
		},
		sites(state){
			return state.sites || [];
		}
	},

	actions: {
		async initStore({commit, dispatch, rootState}, data){
			await dispatch("user/waitLoadUser", undefined, {root: true});
			await Promise.all([
				dispatch("updateWorkspaces", data),
				dispatch("updateCustomerOrganizations"),
				dispatch("updateSites")
			]);

			/*commit(
				"SET_PROJECTS", 
				await api.workspaces.findByUser(
					rootState.user.current.userId,
					data.archived,
					data.limit ?? false,
					data.offset ?? false,
					data.query ?? false
				)
			);*/
		},

		purgeStore({commit}){
			commit("SET_PROJECTS", []);
		},

		async updateWorkspaces({commit, rootState}, data){
			let projects = await api.workspaces.findByUser(
				rootState.user.current.userId,
				data.archived ?? false,
				data.limit ?? false,
				data.offset ?? false,
				data.workspaceName ?? false,
				data.campaignName ?? false,
				data.campaignType ?? false,
				data.organization ?? false,
				data.site ?? false,
				data.reference ?? false
			);
			commit("SET_PROJECTS", {projects: projects, offset: data.offset, limit: data.limit});
		},

		async updateCustomerOrganizations({commit, state}){
			commit("SET_CUSTOMER_ORGANIZATIONS", await api.organizations.findAllCustomerOrganizations());
		},

		async updateSites({commit, state}){
			commit("SET_SITES", await api.sites.findAll());
		},

		async updateWorkspace({commit}, project){
			commit("SET_PROJECT", project);
		},

		async filterWorkspacesByName({commit, rootState}, data){
			commit("SET_PROJECT_FILTER", await api.workspaces.findByUser(
				rootState.user.current.userId,
				data.archived,
				data.limit ?? false,
				data.offset ?? false,
				data.workspaceName ?? false,
				data.campaignName ?? false,
				data.campaignType ?? false,
				data.organization ?? false,
				data.site ?? false,
				data.reference ?? false
			));
		
		}
	}
};
