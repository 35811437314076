import axiosPlugin from "../plugins/axios";
import toastPlugin from "../plugins/messages";
const axios = axiosPlugin._axios;
const toast = toastPlugin.toast;
const getMessage = toastPlugin.getMessage;

const findByCampaign = async(
	id,
	category = null,
	// folder = null,
	// order = null,
	// search = null
) => {
	try {
		let routeURL = `/campaigns/${id}/documents`;
		if(null !== category) routeURL += "?category=" + category;
		// if(null !== folder && folder !== "/") routeURL += "&folder=" + folder;
		// if(null !== order) routeURL += "&order=" + order;
		// if(null !== search) routeURL += "&search=" + search;
		const response = await axios.get(routeURL);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les documents" +
        getMessage(error.response.status)
		);
		throw error;
	}
};

const findDeliveryByCampaign = async(id) => {
	try {
		const response = await axios.get(`/campaigns/${id}/documents/delivery`);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les documents" +
        getMessage(error.response.status)
		);
		throw error;
	}
};

const findCampaignRequiredDocuments = async id => {
	try {
		let routeURL = `/campaigns/${id}/required-documents`;
		const response = await axios.get(routeURL);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les documents requis" +
        getMessage(error.response.status)
		);
		throw error;
	}
};

const download = async(path, responseType = "blob") => {
	try {
		const response = await axios.get("files/" + path, {
			responseType,
			showLoader: false
		});
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de télécharger le document" +
        getMessage(error.response.status)
		);
		throw error;
	}
};

const zipSelected = async(documentPaths, campaignId) => {
	try {
		const response = await axios.post(
			"files/zip",
			{
				"documentPaths": documentPaths,
				"campaignId": campaignId
			},
			{
				responseType: "blob",
				showLoader: false
			}
		);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de télécharger le document" +
        getMessage(error.response.status)
		);
		throw error;
	}
};

const getAllCoresAsPdf = async campaignId => {
	try {
		const response = await axios.get(
			"/coring-campaigns/" + campaignId + "/cores/pdf",
			{
				responseType: "blob",
				showLoader: false
			}
		);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de télécharger le zip" + getMessage(error.response.status)
		);
		throw error;
	}
};

const exportStructuralCsv = async(campaignId, responseType = "blob") => {
	try {
		const response = await axios.get(
			"coring-campaigns/" + campaignId + "/csv-export",
			{
				responseType,
				showLoader: false
			}
		);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible d'exporter le CSV : " + getMessage(error.response.status)
		);
		throw error;
	}
};

const exportCsvLayers = async(campaignId, responseType = "blob") => {
	try {
		const response = await axios.get(
			"coring-campaigns/" + campaignId + "/csv-export-layers",
			{
				responseType,
				showLoader: false
			}
		);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible d'exporter le CSV : " + getMessage(error.response.status)
		);
		throw error;
	}
};

const exportCsvLayersAsbestos = async(campaignId, responseType = "blob") => {
	try {
		const response = await axios.get(
			"coring-campaigns/" + campaignId + "/csv-export-layers-asbestos",
			{
				responseType,
				showLoader: false
			}
		);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible d'exporter le CSV : " + getMessage(error.response.status)
		);
		throw error;
	}
};

const exportCsvZip = async(campaignId, responseType = "blob") => {
	try {
		const response = await axios.get(
			"coring-campaigns/" + campaignId + "/zip-all-csv-exports",
			{
				responseType,
				showLoader: false
			}
		);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible d'exporter le CSV : " + getMessage(error.response.status)
		);
		throw error;
	}
};


const generateDownloadLink = async payload => {
	try {
		const response = await axios.post("/download-links", payload);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de générer le lien" + getMessage(error.response.status)
		);
		throw error;
	}
};

const getDownloadLink = async tokenId => {
	try {
		const response = await axios.get(`/download-links/${tokenId}`);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de trouver le document" + getMessage(error.response.status)
		);
		throw error;
	}
};

const downloadZipFromToken = async tokenId => {
	try {
		const response = await axios.get(`/download-links/${tokenId}/files`, {
			responseType: "blob",
	  		showLoader: true
		});
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de télécharger le zip" + getMessage(error.response.status)
		);
		throw error;
	}
};

/**
 * Upload document to S3 and save object to DB, s3Path will be automatically overwritten
 * @param {FormData} formData files informations and payload
 * @param {object} document infos to store about the document entity
 */
const upload = async(formData, document) => {
	try {
		let response = await axios.post("/documents/upload", formData);
		const s3Path = response.data.path;
		document = await create({...document, s3Path});
		toast("success", "Document ajouté !");
		return document;
	}
	catch (error){
		toast(
			"error",
			"Impossible d'uploader le document" + getMessage(error.response.status)
		);
		throw error;
	}
};

/** Not exported, use upload documents instead */
const create = async document => {
	try {
		const response = await axios.post("/documents", document);
		return response.data;
	}
	catch (error){
		toast("error", "Une erreur est survenue avec le document " + document.name);
		throw error;
	}
};

const uploadMultiple = async(files, campaignId) => {
	try {
		let formData = new FormData();
	
		// Ajouter chaque fichier au formData avec un indice
		let filesCopy = files.filter(file => file.name !== undefined && file.name !== null);
		filesCopy.forEach((file, index) => {
		  formData.append(`files[${index}]`, file);
		});


		if(filesCopy.length !== 0){
			formData.append("campaignId", campaignId);
			formData.append("category", "multiplePlanPDF");
		
			let response = await axios.post("/documents/upload-multiples", formData);
			const s3Paths = response.data;
	
			let documents = files.map((file, index) => ({
				name: file.name,
				extension: /(?:\.([^.]+))?$/.exec(file.name)[1],
				size: file.size,
				category: "multiplePlanPDF",
				campaignId: campaignId,
				s3Path: s3Paths[index],
				requiredDocumentId: null,
				title: file.title ?? null
			}));
	
			documents = await createMultiple(documents);
			toast("success", "Documents ajoutés !");
		}
		else {
			let response = await axios.put("/documents/update-titles", files);
			return response.data;
		}
		return documents;
	  }
	catch (error){
	  toast(
			"error",
			"Impossible d'uploader les documents " + getMessage(error)
	  );
	  throw error;
	}
};

const createMultiple = async documents => {
	try {
	  const response = await axios.post("/documents-multiples", {documents});
	  return response.data;
	}
	catch (error){
	  toast("error", "Une erreur est survenue avec certains documents");
	  throw error;
	}
};

const require = async requiredDocument => {
	try {
		const response = await axios.post("/required-documents", requiredDocument);
		toast("success", "Demande effectuée !");
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible d'effectuer la demande" + getMessage(error.response.status)
		);
		throw error;
	}
};

const review = async(requiredDocumentId, status) => {
	try {
		const response = await axios.patch(
			`/required-documents/${requiredDocumentId}`,
			{status}
		);
		return response.data["pre-signed-url"];
	}
	catch (error){
		toast(
			"error",
			"Impossible de mettre à jour le document" +
        getMessage(error.response.status)
		);
		throw error;
	}
};

const getMergedPDF = async(documentsIds, responseType = "blob") => {
	try {
		const response = await axios.post(
			"/pdf/merge",
			{documentsIds},
			{responseType}
		);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de fusionner les documents" +
        getMessage(error.response.status)
		);
		throw error;
	}
};

const getSplitedPDF = async(documentId, responseType = "blob") => {
	try {
		const response = await axios.post(
			"/pdf/split",
			{
				documentId
			},
			{
				responseType,
				showLoader: false
			}
		);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de séparer les documents" +
        getMessage(error.response.status)
		);
		throw error;
	}
};

const deleteRequiredDocument = async requiredDocumentId => {
	try {
		const response = await axios.delete(
			"/required-documents/" + requiredDocumentId
		);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de supprimer la demande de document." +
        getMessage(error.response.status)
		);
		throw error;
	}
};

const createFolder = async(campaignId, data) => {
	try {
		const response = await axios.post(
			`/campaigns/${campaignId}/folders`, data
		);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de supprimer la demande de document." +
        getMessage(error.response.status)
		);
		throw error;
	}
};

const modifyFolder = async(campaignId, data) => {
	try {
		const response = await axios.put(
			`/campaigns/${campaignId}/folders`, data
		);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de supprimer la demande de document." +
        getMessage(error.response.status)
		);
		throw error;
	}
};	

const deleteFile = async(fileId) => {
	try {
		const response = await axios.delete(`/documents/${fileId}`);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de supprimer la demande de document." +
        getMessage(error.response.status)
		);
		throw error;
	}
};	

export default {
	findByCampaign,
	findCampaignRequiredDocuments,
	download,
	generateDownloadLink,
	getDownloadLink,
	downloadZipFromToken,
	require,
	upload,
	review,
	getMergedPDF,
	exportStructuralCsv,
	zipSelected,
	deleteRequiredDocument,
	getAllCoresAsPdf,
	create,
	findDeliveryByCampaign,
	createFolder,
	modifyFolder,
	getSplitedPDF,
	exportCsvLayers,
	exportCsvLayersAsbestos,
	deleteFile,
	exportCsvZip,
	uploadMultiple
};
