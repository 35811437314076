import axiosPlugin from "../../plugins/axios";
import toastPlugin from "../../plugins/messages";
const axios = axiosPlugin._axios;
const toast = toastPlugin.toast;
const getMessage = toastPlugin.getMessage;

const findAll = async() => {
	try {
		const response = await axios.get("/providers");
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les prestataires" +
        getMessage(error.response.status)
		);
		throw error;
	}
};

const getLabByProviderId = async providerId => {
	try {
		const response = await axios.get(`/provider/${providerId}/laboratories`);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les prestataires" +
        getMessage(error.response.status)
		);
		throw error;
	}
};

export default {
	findAll,
	getLabByProviderId
};
