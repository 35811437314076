<template>
	<header header-slot>
		<section class="section1">
			<div v-show="isBackButtonHidden === false">
				<v-btn
				class="m_brown"
				icon
				@click="$emit('clickBackButton')"
				:to="toBackButton"
				>
					<v-icon large>
						mdi-arrow-left-bold-circle
					</v-icon>
				</v-btn>
			</div>

			<div>
				<h1>
					{{ title }}
				</h1>

				<i>
					{{ subTitle }}
				</i>
			</div>
		</section>

		<section 
		v-show="isButtonHidden === false" 
		class="section2"
		>
			<slot/>
		</section>

		<span>{{ title }}</span>
	</header>
</template>

<script>
export default {
	name: "HeaderSlot", 
	props: {
		isBackButtonHidden: {
			type: Boolean,
			default: false,
			require: false,
		},
		toBackButton: {
			type: Object,
			require: false,
		},
		title: {
			type: String,
			default: "",
			require: false,
		},
		subTitle: {
			type: String,
			default: "",
			require: false,
		},
		isButtonHidden: {
			type: Boolean,
			default: false,
			require: false,
		}
	},
	data(){
		return {

		};
	},
	methods: {

	}
};
</script>

<style lang="scss" scoped>
header[header-slot]{
    position: relative;
    width: 100%;
    height: 72px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 5px;
	flex-shrink: 0;

    > .section1{
        display: flex;
        justify-content: flex-start;
        overflow: hidden;
        flex-grow: 1;
        flex-shrink: 1;

        > :nth-child(1){
            height: 100%;
            width: 36px;
            display: flex;
            justify-content: center;
            margin-top: 20px;
        }

        > :nth-child(2){
            margin-left: 5px;
            overflow: hidden;
            flex-shrink: 1;
            padding-right: 5px;
			display: 'flex';
			align-self: flex-end;

            > h1{
                width: 100%;
                overflow-x: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                height: 48px;
                font-size: 2em;

                &::first-letter{
                    text-transform: uppercase;
                }
            }
        }
    }

    > .section2{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 5px;
    }

    > span{
        position: absolute;
        left: 46px;
        top: 48px;
        background: rgb(128, 128, 128);
		z-index: 4;
        font-size: 12px;
        padding: 4px;
        border-radius: 4px;
        scale: 0;
        transition: all 200ms ease-in-out;
        text-align: center;
        vertical-align: middle;
        transform-origin: center center;
        color: #fff;
    }

    &:has(> .section1 > :nth-child(2) > h1:hover) > span{
        scale: 1;
    }
}
</style>
