import axiosPlugin from "../../plugins/axios";
const axios = axiosPlugin._axios;

export default {
	namespaced: true,
	strict: false,
	state: {
		cores: false,
		selectedCore: false,
		campaigns: false,
		projects: false,
		organizations: false,
		selectedOrganization: false,
		coreOnFocus: false,

		filter: "",
		filterValue: {
			PAH: [
				1000, 500, 50, 0
			],
			TH: [500, 0],
			asbestos: [true, false],
		},
		filterCampaign: [],

		map: {},
	},

	mutations: {
		SET_CORES(state, cores){
			state.cores = cores;
		},
		SET_CAMPAIGNS(state, campaigns){
			state.campaigns = campaigns;
		},
		SET_PROJECTS(state, projects){
			state.projects = projects;
		},
		SET_SELECTED_CORE(state, selectedCore){
			state.selectedCore = selectedCore;
		},
		SET_ORGANIZATIONS(state, organizations){
			state.organizations = organizations;
		},
		SET_SELECTED_ORGANIZATIONS(state, organization){
			state.selectedOrganization = organization;
		},

		SET_FIlTER(state, value){
			state.filter = value;
		},
		SET_FIlTER_PAH(state, value){
			state.filterValue.PAH = value;
		},
		SET_FIlTER_TH(state, value){
			state.filterValue.TH = value;
		},
		SET_FIlTER_ASBESTOS(state, value){
			state.filterValue.asbestos = value;
		},
		SET_FIlTER_CAMPAIGN(state, value){
			state.filterCampaign = value;
		},

		SET_MAP(state, map){
			state.map = map;
		},
		SET_CORE_ON_FOCUS(state, core){
			state.coreOnFocus = core;
		},
	},

	getters: {
		cores(state){
			return state.cores;
		},
		coreOnFocus(state){
			return state.coreOnFocus;
		},
		selectedCore(state){
			return state.selectedCore || {};
		},
		campaigns(state){
			return state.campaigns;
		},
		projects(state){
			return state.projects;
		},
		organizations(state){
			return state.organizations;
		},
		selectedOrganization(state){
			return state.selectedOrganization;
		},

		filter(state){
			return state.filter;
		},
		filterValue(state){
			return state.filterValue;
		},
		filterCampaign(state){
			return state.filterCampaign;
		},

		map(state){
			return state.map;
		},
	},

	actions: {
		async initStore({dispatch, commit}, info){
			let cores, campaigns, projects, organizations;

			switch (info.macroType){
				case "classic":
					[
						{data: cores},
						{data: campaigns},
						{data: projects},
						{data: organizations}
					] = await Promise.all([
						axios.get(`/users/${info.userId}/macro-cores`),
						axios.get(`/users/${info.userId}/macro-campaigns`),
						axios.get(`/users/${info.userId}/macro-projects`),
						axios.get(`/users/${info.userId}/macro-organizations`)
					]);
					break;
		
				case "campaigns":
					[
						{data: cores},
						{data: campaigns},
						{data: projects},
						{data: organizations}
					] = await Promise.all([
						axios.get(`/organizations/${info.organizationId}/macro-cores`),
						axios.get(`/organizations/${info.organizationId}/macro-campaigns`),
						axios.get(`/organizations/${info.organizationId}/macro-projects`),
						axios.get(`/organizations/${info.organizationId}`).then(response => ({data: [response.data]}))
					]);
					break;

				case "project":
					[
						{data: cores},
						{data: campaigns}
					] = await Promise.all([
						axios.get(`/projects/${info.projectId}/macro-cores`),
						axios.get(`/projects/${info.projectId}/macro-campaigns`)
					]);
					break;
		
				default:
					throw new Error(`Unknown type: ${info.type}`);
			}
			
			commit("SET_CORES", cores);
			commit("SET_CAMPAIGNS", campaigns);
			if(info.macroType !== "project"){
				commit("SET_PROJECTS", projects);
				commit("SET_ORGANIZATIONS", organizations);
				commit("SET_SELECTED_ORGANIZATIONS", organizations[0]?.id || false);
				projects.forEach(e => dispatch("selectProject", e.id));
			}
		},

		purgeStore({commit}){
			commit("SET_CORES", false);
			commit("SET_CAMPAIGNS", false);
			commit("SET_PROJECTS", false);
			commit("SET_FIlTER_CAMPAIGN", []);
			commit("SET_SELECTED_CORE", false);
			commit("SET_MAP", false);
		},

		selectCore({commit}, core){
			commit("SET_SELECTED_CORE", core);
		},

		unSelectCore({commit}){
			commit("SET_SELECTED_CORE", false);
		},

		setFilterType({commit}, type){
			commit("SET_FIlTER", type);
		},
		addValueToFilter({commit, state}, value){
			commit(
				"SET_FIlTER_" + state.filter.toUpperCase(), 
				[...state.filterValue[state.filter], value]
			);
		},
		removeValueToFilter({commit, state}, value){
			commit(
				"SET_FIlTER_" + state.filter.toUpperCase(), 
				state.filterValue[state.filter].filter(v => v !== value)
			);
		},
		selectProject({commit, state}, id){
			commit(
				"SET_FIlTER_CAMPAIGN",
				[...state.filterCampaign, ...state.campaigns.filter(v => v.projectId === id).map(v => v.id)]
			);
		},
		unSelectProject({commit, state}, id){
			commit(
				"SET_FIlTER_CAMPAIGN",
				state.filterCampaign.filter(v => v.projectId !== id)
			);
		},
		selectCampaign({commit, state}, id){
			commit(
				"SET_FIlTER_CAMPAIGN",
				[...state.filterCampaign, id]
			);
		},
		unSelectCampaign({commit, state}, id){
			commit(
				"SET_FIlTER_CAMPAIGN",
				state.filterCampaign.filter(v => v !== id)
			);
		},
		setCoreOnFocus({commit}, core){
			commit("SET_CORE_ON_FOCUS", core);
		},
	}
};
