import axiosPlugin from "../../plugins/axios";
import toastPlugin from "../../plugins/messages";
const axios = axiosPlugin._axios;
const toast = toastPlugin.toast;
const getMessage = toastPlugin.getMessage;

const findAll = async(isInternal = null, loader = false) => {
	try {
		let organizationUrl = "/organizations";
		if(null !== isInternal) organizationUrl += "?isInternal=" + isInternal;
		const response = await axios.get(organizationUrl, {
			showLoader: loader
		});
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les organisations" +
        getMessage(error.response.status)
		);
		throw error;
	}
};
const findById = async id => {
	try {
		const response = await axios.get(`/organizations/${id}`);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer l'organisation" +
        getMessage(error.response.status)
		);
		throw error;
	}
};
const findUserOrganizations = async userId => {
	try {
		const response = await axios.get(`/users/${userId}/organizations`);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les organisations" +
        getMessage(error.response.status)
		);
		throw error;
	}
};
const create = async organization => {
	try {
		const response = await axios.post("/organizations", organization);

		toast("success", "Organisation créée !");

		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de créer l'organisation" + getMessage(error.response.status)
		);
		throw error;
	}
};
const edit = async(id, organization) => {
	try {
		const response = await axios.put(`/organizations/${id}`, organization);

		toast("success", "Organisation modifiée !");
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de modifier l'organisation" +
        getMessage(error.response.status)
		);
		throw error;
	}
};

const remove = async id => {
	try {
		await axios.delete(`/organizations/${id}`);
		toast("success", "Organisation supprimée !");
	}
	catch (error){
		toast(
			"error",
			"Impossible de supprimer l'organisation" +
        getMessage(error.response.status)
		);
		throw error;
	}
};

const getRelations = async organizationId => {
	try {
		const response = await axios.get(
			`/organizations/${organizationId}/relations`
		);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les entités liées à l'organisation" +
        getMessage(error.response.status)
		);
		throw error;
	}
};

/**
 * Upload file to S3 and save object to DB, s3Path will be automatically overwritten
 * @param {FormData} formData files informations and payload
 */
const uploadFile = async(formData, organizationId) => {
	try {
		let response = await axios.post(`/organizations/${organizationId}/upload-files`, formData);
		const s3Path = response.data.path;

		return s3Path;
	}
	catch (error){
		toast(
			"error",
			"Impossible d'uploader le fichier" + getMessage(error.response.status)
		);
		throw error;
	}
};

const deleteFile = async(organizationId) => {
	try {
		let response = await axios.delete(`/organizations/${organizationId}/logo`);
	}
	catch (error){
		toast(
			"error",
			"Impossible de supprimer le fichier" + getMessage(error.response.status)
		);
		throw error;
	}
};

const getFileUrl = async organizationId => {
	try {
		let response = await axios.get(`organizations/${organizationId}/presigned`);

		return response.data["pre-signed-url"];
	}
	catch (error){
		toast(
			"error",
			"Impossible de générer une URL" + getMessage(error.response.status)
		);
		throw error;
	}
};

const deactivateAllSites = async(organizationId, activation) => {
	try {
		let response = await axios.post(`organizations/${organizationId}/deactivate`, activation);

		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de générer une URL" + getMessage(error.response.status)
		);
		throw error;
	}
};

const getAllSitesFromOrganization = async organizationId => {
	try {
		let response = await axios.get(`organizations/${organizationId}/sites`, {
			showLoader: false
		});

		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les sites" + getMessage(error.response.status)
		);
		throw error;
	}
};

const findAllCustomerOrganizations = async() => {
	try {
		let response = await axios.get("/customersOrganizations", {
			showLoader: false
		});

		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les organizations clientes" + getMessage(error.response.status)
		);
		throw error;
	}
};

export default {
	findAll,
	findById,
	findUserOrganizations,
	create,
	edit,
	delete: remove,
	getRelations,
	uploadFile,
	getFileUrl,
	deactivateAllSites,
	getAllSitesFromOrganization,
	deleteFile,
	findAllCustomerOrganizations
};
