import Vue from "vue";
import Vuex from "vuex";
import campaigns from "./modules/campaigns";
import filesNotifications from "./modules/filesNotifications";
import user from "./modules/user";
import campaign from "./modules/campaign";
import loader from "./modules/loader";
import notifications from "./modules/notifications";
import workspace from "./modules/workspace";
import coreCampaignPictures from "./modules/coreCampaignPictures";
import cores from "./modules/cores";
import coring from "./modules/coring";
import project from "./modules/project";
import coringMacro from "./modules/coringMacro";
import groundSurvey from "./modules/groundSurvey";
import downloader from "./modules/downloader";
import organizations from "./modules/organizations";

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		user,
		campaigns,
		filesNotifications,
		campaign,
		loader,
		notifications,
		workspace,
		coreCampaignPictures,
		cores,
		coring,
		project,
		coringMacro,
		groundSurvey,
		organizations,
		downloader
	},
});
