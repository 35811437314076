<template>
	<div class="leftView-container">
		<div class="logo"/>

		<div class="tw-flex tw-justify-around tw-w-full">
			<p class="text-size">
				<strong>M</strong>anagement <strong>A</strong>ssisté par <strong>O</strong>rdinateur des <strong>R</strong>éseaux, <strong>I</strong>nfrastructures et de l’<strong>E</strong>nvironnement
			</p>
		</div>
	</div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.leftView-container {
  height: 100vh;
  width: 50vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FBF5F3;
  justify-content: center;

  .text-size {
	font-size: 20px;
  }

  .logo {
    background-image: url(../../assets/images/MAORIE_RVB-low_black.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 70%;
    height: 200px;
  }
  .movie {
    display: flex;
    justify-content: center;
    .presentationMovie {
      width: 90%;
      height: auto;
    }
  }
}
</style>
