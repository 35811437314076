<template>
	<v-expansion-panels
	v-model="isOpen"
	class="downloadTab"
	v-if="downloading"
	>
		<v-expansion-panel>
			<v-expansion-panel-header class="pt-0 pb-0">
				<h3>Liste des téléchargements en cours</h3>
			</v-expansion-panel-header>

			<v-expansion-panel-content>
				<div class="panel">
					<div
					v-for="file in downloadList"
					class="tw-flex tw-justify-between"
					>
						<span class="text">
							{{ file.name }}
						</span>
						<v-icon :color="getColor(file.downloadState)">
							{{ downloadState[file.downloadState] }}
						</v-icon>
					</div>
				</div>

				<div class="tw-flex tw-justify-center tw-mt-[10px]">
					<ButtonSlot
					@click="validate"
					class="tw-w-[300px]"
					>
						Vider / fermer la liste
					</ButtonSlot>
				</div>
			</v-expansion-panel-content>
		</v-expansion-panel>
	</v-expansion-panels>
</template>
  
<script>
import {mapActions, mapGetters} from "vuex";
  
export default {
	name: "DownloadTab",
	data(){
		return {
			isOpen: true,
			downloadState: {
				finished: "mdi-check-bold",
				loading: "mdi-download-circle-outline",
				failed: "mdi-close-thick"
			},
			downloadStateColor: {
				finished: "green",
				loading: "grey",
				failed: "red"
			}
		};
	},
	computed: {
	  ...mapGetters("downloader", ["downloading", "downloadList"]),
	},
	watch: {
		downloadList(){
			if(this.downloadList.length === 0){
				this.setDownloading(false);
			}
		}
	},
	methods: {
		...mapActions("downloader", ["setDownloading", "deleteFromDownloadList"]),
		validate(){
			this.downloadList.forEach(e => {
				this.deleteFromDownloadList(e.name);
			});
		},
		getColor(dwState){
			return this.downloadStateColor[dwState];
		}
	}
	
};
</script>
  
  <style scoped>
  .downloadTab {
	position: absolute;
	right: 0;
	bottom: 0px;
	width: 500px;
	border: solid black 1px;
	border-radius: 4px 4px 0 0;
  }
  .panel {
	min-height: 50px;
	max-height: 150px;
	overflow-y: auto;
	overflow-x: hidden;
  }
  .text {
	max-width: 400px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: pre;
  }
  </style>
  
