import axiosPlugin from "../../plugins/axios";
import toastPlugin from "../../plugins/messages";
import router from "../../router";
const axios = axiosPlugin._axios;
const toast = toastPlugin.toast;
const getMessage = toastPlugin.getMessage;


const create = async(data) => {
	try {
		const response = await axios.post("/pdfParams", data);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de créer les options PDF" + getMessage(error.response.status)
		);
		throw error;
	}
};

const update = async(id, data) => {
	try {
		const response = await axios.put(`/pdfParams/${id}`, data);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de modifier les options PDF" + getMessage(error.response.status)
		);
		throw error;
	}
};

const getPicture = async(pdfParamId, category) => {
	try {
		let response = await axios.get(`pdfParams/${pdfParamId}/presigned?category=${category}`);

		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de générer une URL" + getMessage(error.response.status)
		);
		throw error;
	}
};

export default {
	create,
	update,
	getPicture
};
