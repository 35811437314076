import axiosPlugin from "../../plugins/axios";
import toastPlugin from "../../plugins/messages";
import router from "../../router";
const axios = axiosPlugin._axios;
const toast = toastPlugin.toast;
const getMessage = toastPlugin.getMessage;

const getPresignedUrl = async(downloadId) => {
	try {
		const response = await axios.get(`/downloads/${downloadId}/presigned-link`);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer le lien présigné" +
			getMessage(error.response.status)
		);
		throw error;
	}
};

const getAskedDocuments = async(campaignId) => {
	try {
		const response = await axios.get(`/campaigns/${campaignId}/downloads?type=raw_data`);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer le lien présigné" +
			getMessage(error.response.status)
		);
		throw error;
	}
};

const createDocumentDownload = async(campaignId, category = null) => {
	try {
		let route = `/campaigns/${campaignId}/downloads/create?type=raw_data`;
		if(category !== null){
			route += `&categorie=${category}`;
		}
		const response = await axios.get(route);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer le lien présigné" +
			getMessage(error.response.status)
		);
		throw error;
	}
};


export default {
	getPresignedUrl,
	getAskedDocuments,
	createDocumentDownload
};
