<template>
	<div id="disconnected">
		<LeftView/>
		<router-view/>
	</div>
</template>

<script>
import LeftView from "../components/login/LeftView.vue";

export default {
	name: "DisconnectedLayout",
	components: {LeftView}
};
</script>

<style lang="scss">
* {
  box-sizing: border-box;
}

#disconnected {
  display: flex;
}
</style>
