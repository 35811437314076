import axiosPlugin from "../plugins/axios";
import toastPlugin from "../plugins/messages";
const axios = axiosPlugin._axios;
const toast = toastPlugin.toast;
const getMessage = toastPlugin.getMessage;

const getAll = async() => {
	try {
		const response = await axios.get("/maorie-versions");
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les documents" +
        getMessage(error.response.status)
		);
		throw error;
	}
};

const create = async(data) => {
	try {
		const response = await axios.post("/maorie-versions", data);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les documents" +
        getMessage(error.response.status)
		);
		throw error;
	}
};

const edit = async(id, data) => {
	try {
		const response = await axios.put(`/maorie-versions/${id}`, data);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les documents" +
        getMessage(error.response.status)
		);
		throw error;
	}
};

export default {
	getAll,
	create,
	edit
};
