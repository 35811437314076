import {api} from "../../api";

export default {
	namespaced: true,
	strict: false,

	state: {
		project: false,
		campaigns: [],
		pageLoaded: [],
		refreshNewCampaign: false,
		campaignFilter: false,
		campaignPage: false,
		isArchived: false,
		campaignReference: false
	},

	mutations: {
		SET_PROJECT(state, project){
			state.project = project;
		},
		SET_CAMPAIGNS(state, payload){
			const {campaigns, offset, limit} = payload;
			let page = (offset / limit) + 1;
			let normalLengthPage = (page - 1) * limit;

			if(page !== 1 && normalLengthPage >= state.campaigns.length){
				let diffencePage = normalLengthPage - state.campaigns.length;
				while(diffencePage > 0){
					state.campaigns.push([]);
					diffencePage--;
				}
				campaigns.forEach(campaign => {
					state.campaigns.push(campaign);
				});
			}

			else {
				if(Math.max(state.pageLoaded) !== page - 1){
					let max = offset + limit;
					let index = 0;
					for(let i = offset; i < max; i++){
						state.campaigns[i] = campaigns[index];
						index++;
					}
				}
				else {
					campaigns.forEach(campaign => {
						state.campaigns.push(campaign);
					});
				}
			}
		},
		SET_CAMPAIGN_FILTER(state, campaigns){
			state.campaigns = campaigns;
		},
		UPDATE_CAMPAIGN(state, campaign){
			let index = state.campaigns.findIndex(c => c.id === campaign.id);
			state.campaigns[index] = campaign;
			state.campaigns = [...state.campaigns];
		},
		SET_PAGELOADED(state){
			state.pageLoaded = [];
			state.pageLoaded.push(1);
		},
		UPDATE_PAGELOADED(state, number){
			state.pageLoaded.push(number);
		},
		CLEAR_CAMPAIGNS(state){
			state.campaigns = [];
			state.pageLoaded = [];
		},
		SET_REFRESH_NEW_CAMPAIGN(state, value){
			state.refreshNewCampaign = value;
		},
		SET_CAMPAIGN_PAGE(state, value){
			state.campaignPage = value;
		},
		SET_IS_ARCHIVED(state, value){
			state.isArchived = value;
		},
		SET_CAMPAIGN_REFERENCE(state, value){
			state.campaignReference = value;
		}
	},

	getters: {
		project(state){
			return state.project;
		},
		campaigns(state){
			return state.campaigns;
		},
		refreshNewCampaign(state){
			return state.refreshNewCampaign;
		},
		getPageLoaded(state){
			return state.pageLoaded;
		},
		campaignFilter(state){
			return state.campaignFilter;
		},
		campaignPage(state){
			return state.campaignPage;
		},
		isArchived(state){
			return state.isArchived;
		},
		campaignReference(state){
			return state.campaignReference;
		}
	},

	actions: {
		async initStore({commit}, data){
			commit("SET_PROJECT", await api.workspaces.findById(data.workspaceId));
			let campaigns = await api.campaigns.findAllFromWorkspace(data.workspaceId, {}, true, data.limit ?? false, data.offset ?? false, data.query ?? false, false, false, false, data.reference ?? null);
			commit("SET_CAMPAIGNS", {campaigns: campaigns, offset: data.offset, limit: data.limit});
		},

		purgeStore({commit}){
			commit("SET_PROJECT", false);
			commit("CLEAR_CAMPAIGNS");
		},

		async updateCampaigns({commit}, data){
			let campaigns = await api.campaigns.findAllFromWorkspace(data.workspaceId, {}, true, data.limit ?? false, data.offset ?? false, data.query ?? false, data.status ?? null, data.campaignType ?? null, data.archived, data.reference ?? null);
			commit("SET_CAMPAIGNS", {campaigns: campaigns, offset: data.offset, limit: data.limit});
		},

		async updateCampaign({commit}, id){
			commit("UPDATE_CAMPAIGN", await api.campaigns.findById(id));
		},
		async updateCampaignData({commit}, data){
			commit("UPDATE_CAMPAIGN", data);
		},
		async filterCampaignsByName({commit}, data){
			commit("SET_CAMPAIGN_FILTER", await api.campaigns.findAllFromWorkspace(data.workspaceId, {}, true, data.limit ?? false, data.offset ?? false, data.query ?? false, data.status ?? null, data.campaignType ?? null, data.archived, data.reference ?? null));
		},
		async setProject({commit}, id){
			commit("SET_PROJECT", await api.workspaces.findById(id));
		}
	}
};
