import axiosPlugin from "../plugins/axios";
import toastPlugin from "../plugins/messages";
const axios = axiosPlugin._axios;
const toast = toastPlugin.toast;
const getMessage = toastPlugin.getMessage;

const getCoringCampaignsStats = async(agencyId, siteId) => {
	try {
		let filter = "";
		if(agencyId !== null && siteId !== null){
			filter = "?agencyId=" + agencyId + "&siteId=" + siteId;
		}
		else if(agencyId !== null){
			filter = "?agencyId=" + agencyId;
		}
		else if(siteId !== null){
			filter = "?siteId=" + siteId;
		}
		const response = await axios.get("/stats/coring-campaigns" + filter);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les statistiques" +
        getMessage(error.response.status)
		);
		throw error;
	}
};

const getCoringCampaignsStatsV2 = async(agencyId, siteId, startDate = null, endDate = null) => {
	try {
		let params = [];

		if(agencyId !== null){
			params.push("agencyId=" + agencyId);
		}
		if(siteId !== null){
			params.push("siteId=" + siteId);
		}
		if(startDate !== null){
			params.push("startDate=" + startDate);
		}
		if(endDate !== null){
			params.push("endDate=" + endDate);
		}

		const queryString = params.length > 0 ? "?" + params.join("&") : "";

		const response = await axios.get("/stats/coring-campaigns/v2" + queryString);

		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les statistiques" +
        getMessage(error.response.status)
		);
		throw error;
	}
};

const getUsersStats = async(period, startDate = null, endDate = null) => {
	try {
		let params = [];

		if(period !== null){
			params.push("period=" + period);
		}
		if(startDate !== null){
			params.push("startDate=" + startDate);
		}
		if(endDate !== null){
			params.push("endDate=" + endDate);
		}

		const queryString = params.length > 0 ? "?" + params.join("&") : "";

		const response = await axios.get("/stats/external-users-connexions" + queryString);

		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les statistiques" +
        getMessage(error.response.status)
		);
		throw error;
	}
};

export default {getCoringCampaignsStats, getCoringCampaignsStatsV2, getUsersStats};
