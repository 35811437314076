import axiosPlugin from "../../plugins/axios";
import toastPlugin from "../../plugins/messages";
const axios = axiosPlugin._axios;
const toast = toastPlugin.toast;
const getMessage = toastPlugin.getMessage;

const findByWorkspace = async workspaceId => {
	try {
		const response = await axios.get(`/workspaces/${workspaceId}/cities`, {
			showLoader: false
		});
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les communes" + getMessage(error.response.status)
		);
		throw error;
	}
};

const create = async city => {
	try {
		const response = await axios.post("/cities", city, {
			showLoader: false
		});

		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de créer la commune" + getMessage(error.response.status)
		);
		throw error;
	}
};

export default {
	findByWorkspace,
	create
};
