import axiosPlugin from "../../plugins/axios";
import toastPlugin from "../../plugins/messages";
const axios = axiosPlugin._axios;
const toast = toastPlugin.toast;
const getMessage = toastPlugin.getMessage;


const findByCampaign = async(id, coringResult, showPrestations = false) => {
	try {
		const response = await axios.get(`/campaigns/${id}/cores` + (coringResult ? "?coringResult=true" : "") + (showPrestations ? "?showPrestations=true" : ""), {
			showLoader: coringResult || false
		});
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les carottes" + getMessage(error.response.status)
		);
		throw error;
	}
};

const get = async id => {
	try {
		const response = await axios.get(`/cores/${id}`);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les carottes" + getMessage(error.response.status)
		);
		throw error;
	}
};

const getPdf = async id => {
	try {
		const response = await axios.get(`/cores/${id}/pdf`, {
			responseType: "arraybuffer",
			showLoader: false
		});
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer le pdf" + getMessage(error.response.status)
		);
		throw error;
	}
};

const create = async core => {
	try {
		const response = await axios.post("/cores", core, {
			showLoader: false
		});
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de créer la carotte" + getMessage(error.response.status)
		);
		throw error;
	}
};

const edit = async(core) => {
	try {
		const response = await axios.put(`/cores/${core.id}`, core);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de modifier la carotte" + getMessage(error.response?.status)
		);
		throw error;
	}
};

const patch = async(core) => {
	try {
		const response = await axios.patch(`/cores/${core.id}`, core);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de modifier la carotte" + getMessage(error.response?.status)
		);
		throw error;
	}
};

const del = async(core) => {
	try {
		await axios.delete(`/cores/${core.id}?force=true`);
	}
	catch (error){
		toast(
			"error",
			"Impossible de supprimer la carotte" + getMessage(error.response.status)
		);
		throw error;
	}
};

const cancel = async(id, note = "") => {
	try {
		await axios.delete(`/cores/${id}`, {note});
	}
	catch (error){
		toast(
			"error",
			"Impossible de supprimer la carotte" + getMessage(error.response.status)
		);
		throw error;
	}
};

const getCoresPdf = async(coresIds) => {
	try {

		let url = "/cores-pdf";
		coresIds.forEach((coresId, index) => {
			index === 0
				? (url += `?coreIds[]=${coresId}`)
				: (url += `&coreIds[]=${coresId}`);
		});
		let response = await axios.get(url, {
			responseType: "arraybuffer",
			showLoader: false
		});
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer le PDF " + getMessage(error.response.status)
		);
		throw error;
	}
};

const validateCoresByCampaign = async(campaignId) => {
	try {
		let response = await axios.post(`/campaign/${campaignId}/validateCores`);
		return response.data;
	}
	catch (error){
		toast("error",
			"Impossible de mettre les carottes à jours" + getMessage(error.response.status)
		);
		throw error;
	}
};

export default {
	findByCampaign,
	getPdf,
	create,
	edit,
	cancel,
	get,
	del,
	getCoresPdf,
	patch,
	validateCoresByCampaign
};
