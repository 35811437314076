import axiosPlugin from "../../plugins/axios";
import toastPlugin from "../../plugins/messages";
import coringLayerType from "../../utils/coringCampaign/coringlayertype.const";
const axios = axiosPlugin._axios;
const toast = toastPlugin.toast;
const getMessage = toastPlugin.getMessage;

const findByCampaign = async id => {
	try {
		const response = await axios.get(`/coring-campaigns/${id}/map-layers`);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les couches" + getMessage(error.response.status)
		);
		throw error;
	}
};

const getLegends = async id => {
	let legends = [];
	switch (id % (-(coringLayerType.LAYER_TYPE_COUNT + 1))){
		case coringLayerType.HAP:
			legends.push({
				color: "#000",
				excludeMax: false,
				excludeMin: false,
				id: "1_1",
				label: "HAP ≥ 1000 mg/kg",
				max: 9999999,
				min: 1000,
				position: 1,
				valueExact: null,
				thickness: 18
			});
			legends.push({
				color: "#ff0d0d",
				excludeMax: true,
				excludeMin: false,
				id: "1_2",
				label: "500 ≤ HAP < 1000 mg/kg",
				max: 1000,
				min: 500,
				position: 2,
				valueExact: null,
				thickness: 18
			});
			legends.push({
				color: "#ff8e15",
				excludeMax: true,
				excludeMin: false,
				id: "1_3",
				label: "50 ≤ HAP < 500 mg/kg",
				max: 500,
				min: 50,
				position: 3,
				valueExact: null,
				thickness: 18
			});
			legends.push({
				color: "#55863A",
				excludeMax: true,
				excludeMin: false,
				id: "1_4",
				label: "HAP < 50 mg/kg",
				max: 50,
				min: 0,
				position: 4,
				valueExact: null,
				thickness: 18
			});
			break;
		case coringLayerType.HCT:
			legends.push({
				color: "#69b34c",
				excludeMax: false,
				excludeMin: false,
				id: "2_1",
				label: "HAP ≥ 500 mg/kg",
				max: 9999999,
				min: 500,
				position: 1,
				valueExact: null,
				thickness: 18
			});
			legends.push({
				color: "#55863A",
				excludeMax: true,
				excludeMin: false,
				id: "2_2",
				label: "HAP < 500 mg/kg",
				max: 500,
				min: 0,
				position: 2,
				valueExact: null,
				thickness: 18
			});
			break;
		case coringLayerType.AMIANTE:
		case coringLayerType.STRUCTURAL:
			legends.push({
				color: "#CF3831",
				excludeMax: false,
				excludeMin: false,
				id: "3_1",
				label: "Présence",
				max: 0,
				min: 0,
				position: 1,
				valueExact: ["true"],
				thickness: 18
			});
			legends.push({
				color: "#55863A",
				excludeMax: true,
				excludeMin: false,
				id: "3_2",
				label: "Non présence",
				max: 500,
				min: 0,
				position: 2,
				valueExact: ["false"],
				thickness: 18
			});
			break;
	}
	return legends;
};

const getCommonPRsLayer = async layerName => {
	try {
		const response = await axios.get(
			"/map-layers/common-prs-layer/" + layerName
		);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les données" + getMessage(error.response.status)
		);
		throw error;
	}
};

export default {
	findByCampaign,
	getLegends,
	getCommonPRsLayer
};
