import axiosPlugin from "../../plugins/axios";
import toastPlugin from "../../plugins/messages";
const axios = axiosPlugin._axios;
const toast = toastPlugin.toast;
const getMessage = toastPlugin.getMessage;

const findByCampaign = async id => {
	try {
		const response = await axios.get(`/campaigns/${id}/map-layers`);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les couches" + getMessage(error.response.status)
		);
		throw error;
	}
};

const getLegends = async id => {
	try {
		const response = await axios.get(`/map-layers/${id}/value-classes`, {
			showLoader: false
		});
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les légendes" + getMessage(error.response.status)
		);
		throw error;
	}
};

const getImageSettings = async layerId => {
	try {
		const response = await axios.get(
			"/map-layers/" + layerId + "/imageSettings"
		);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les données" + getMessage(error.response.status)
		);
		throw error;
	}
};

const getCommonPRsLayer = async layerName => {
	try {
		const response = await axios.get(
			"/map-layers/common-prs-layer/" + layerName
		);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les données" + getMessage(error.response.status)
		);
		throw error;
	}
};

const getFiltersList = async(campaignId, category, status = null) => {
	try {
		const response = await axios.get(
			`/campaigns/${campaignId}/dynamic-filters/${category}?` + (null !== status ? `status=${status}` : "")
		);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les données du filtre" +
        getMessage(error.response.status)
		);
		throw error;
	}
};

const getColumnFilter = async(id, category, columnName, visibleMapLayerIds = []) => {
	try {
		const response = await axios.get(
			`/campaigns/${id}/standard-column-values/${columnName}/${category}?with-current-filters=true&visible-maplayers=${visibleMapLayerIds.join(",")}`,
			{
				showLoader: true
			}
		);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les valeurs : de id " + 
          id + ", de category : " + 
          category + ", de columnName " + 
          columnName + ", " + getMessage(error.response.status)
		);
		throw error;
	}
};

const putNewFilter = async(id, filter) => {
	try {
		const response = await axios.put(`/dynamic-filters/${id}`, filter);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les valeurs" + getMessage(error.response.status)
		);
		throw error;
	}
};

const getFilterValueByUser = async id => {
	try {
		const response = await axios.get(`/dynamic-filter-values/${id}`, {showLoader: true});
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les valeurs" + getMessage(error.response.status)
		);
		throw error;
	}
};

const updateSchemaValues = async(id, gid, data) => {
	try {
		const response = await axios.put(`/map-layers/${id}/dynamic-schemas/${gid}`, data);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de mettre les valeurs à jours" + getMessage(error.response.status)
		);
		throw error;
	}
};

const getDataFromColumnName = async(mapLayer, columnName) => {
	try {
		const response = await axios.get(`/map-layers/${mapLayer}/field-data/${columnName}`, {showLoader: false});
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les données de ce champs" + getMessage(error.response.status)
		);
		throw error;
	}
};

const resetAllFiltersByLayers = async(layersId) => {
	try {
		const response = await axios.get(`/dynamic-filters/reset-by-layers?selected-maplayers=${layersId.join(",")}`);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de supprimer les filtres de cette couche" + getMessage(error.response.status)
		);
		throw error;
	}
};

export default {
	findByCampaign,
	getLegends,
	getImageSettings,
	getCommonPRsLayer,
	getFiltersList,
	getColumnFilter,
	putNewFilter,
	getFilterValueByUser,
	updateSchemaValues,
	getDataFromColumnName,
	resetAllFiltersByLayers
};
