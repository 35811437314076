<template>
	<div id="connected">
		<v-navigation-drawer
		v-model="drawer"
		:mini-variant="mini"
		app
		left
		class="m_navigation tw-z-20"
		permanent
		>
			<div class="logo-div">
				<v-btn
				icon
				@click.stop="mini = !mini"
				:class="mini ? 'm_expand_icon align-middle' : 'm_retract_icon mr-2'"
				>
					<!-- opened -->
					<v-icon
					v-if="mini === false"
					color="white"
					dense
					>
						mdi-close
					</v-icon>
					<!-- closed -->
					<v-icon
					v-if="mini === true"
					color="white"
					dense
					>
						mdi-menu
					</v-icon>
				</v-btn>
			</div>

			<router-link
			:to="
				$hasRight('globalActions.showHomeBtn') ? { name: 'workspaces' } : ''
			"
			class="text-decoration-none m_primary_links"
			>
				<img
				v-if="!mini"
				class="logo"
				src="../assets/images/MAORIE_BLANC-low.png"
				/>
			</router-link>

			<v-divider/>

			<v-list
			dense
			v-if="undefined === user.displayName"
			>
				<v-list-item
				@click="openNotifications()"
				class="m_primary_links mb-2"
				:exact-active-class="
					mini ? 'active-class-list-mini' : 'active-class-list'
				"
				>
					<v-tooltip
					v-if="mini"
					right
					nudge-right="20"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-list-item-icon
							class="mr-4"
							v-bind="attrs"
							v-on="on"
							>
								<v-icon :class="notifIconClass">
									mdi-bell
								</v-icon>
							</v-list-item-icon>
						</template>

						<span>{{ notificationsCount }} Notification(s)</span>
					</v-tooltip>

					<v-list-item-icon
					class="mr-4"
					v-else
					>
						<v-icon :class="notifIconClass">
							mdi-bell
						</v-icon>
					</v-list-item-icon>

					<v-list-item-content>
						<v-list-item-title class="white--text">
							{{ notificationsCount }} Notification(s)
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>

			<v-list
			dense
			v-if="undefined === user.displayName"
			>
				<v-subheader
				class="white--text font-weight-bold ml-2"
				v-if="!mini"
				>
					PROJETS
				</v-subheader>

				<v-list-item
				v-for="item in projectItems"
				:key="item.title"
				:to="item.to"
				v-on="item.click ? { click: item.click } : null"
				link
				class="m_primary_links"
				:exact-active-class="
					mini ? 'active-class-list-mini' : 'active-class-list'
				"
				>
					<v-tooltip
					v-if="mini"
					right
					nudge-right="20"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-list-item-icon
							class="mr-4"
							v-bind="attrs"
							v-on="on"
							>
								<v-icon
								:class="!mini ? 'm_primary_icon' : ''"
								class="white--text"
								>
									{{ item.icon }}
								</v-icon>
							</v-list-item-icon>
						</template>

						<span>{{ item.title }}</span>
					</v-tooltip>

					<v-list-item-icon
					class="mr-4"
					v-else
					>
						<v-icon
						:class="!mini ? 'm_primary_icon' : ''"
						class="white--text"
						>
							{{ item.icon }}
						</v-icon>
					</v-list-item-icon>

					<v-list-item-content>
						<v-list-item-title class="white--text">
							{{
								item.title
							}}
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>

			<v-list
			dense
			>
				<v-subheader
				class="white--text font-weight-bold ml-2"
				v-if="!mini"
				>
					DOSSIERS
				</v-subheader>

				<v-list-item
				v-if="$hasRight('files.accessCustomerFiles') && displayFolderMenuRoutes.includes($route.name)"
				link
				class="m_primary_links"
				:to="{
					name: 'campaign-files',
					params: { id: $route.params.id }
				}"
				:exact-active-class="
					mini ? 'active-class-list-mini' : 'active-class-list'
				"
				>
					<v-tooltip
					v-if="mini"
					right
					nudge-right="20"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-list-item-icon
							v-bind="attrs"
							v-on="on"
							>
								<v-icon
								:class="!mini ? 'm_primary_icon' : ''"
								class="white--text"
								>
									mdi-file-chart-outline
								</v-icon>
							</v-list-item-icon>
						</template>

						<span>Fichiers partagés</span>
					</v-tooltip>

					<v-list-item-icon v-else>
						<v-icon
						:class="!mini ? 'm_primary_icon' : ''"
						class="white--text"
						>
							mdi-file-chart-outline
						</v-icon>
					</v-list-item-icon>

					<v-list-item-content>
						<v-list-item-title class="white--text">
							Fichiers partagés
							<v-chip
							v-if="$store.state.filesNotifications.files > 0"
							class="ml-2"
							small
							>
								{{ $store.state.filesNotifications.files }}
							</v-chip>
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item
				v-if="$hasRight('files.accessDeliverables')"
				link
				class="m_primary_links"
				@click="() => redirectDeliverables()"
				:exact-active-class="
					mini ? 'active-class-list-mini' : 'active-class-list'
				"
				>
					<v-tooltip
					v-if="mini"
					right
					nudge-right="20"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-list-item-icon
							v-bind="attrs"
							v-on="on"
							>
								<v-icon
								:class="!mini ? 'm_primary_icon' : ''"
								class="white--text"
								>
									mdi-file-check-outline
								</v-icon>
							</v-list-item-icon>
						</template>

						<span>Livrables</span>
					</v-tooltip>

					<v-list-item-icon v-else>
						<v-icon
						:class="!mini ? 'm_primary_icon' : ''"
						class="white--text"
						>
							mdi-file-check-outline
						</v-icon>
					</v-list-item-icon>

					<v-list-item-content>
						<v-list-item-title class="white--text">
							Livrables
							<v-chip
							v-if="$store.state.filesNotifications.livreries > 0"
							class="ml-2"
							small
							>
								{{ $store.state.filesNotifications.livreries }}
							</v-chip>
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>

			<v-list dense>
				<v-subheader
				class="white--text font-weight-bold ml-2"
				v-if="!mini"
				>
					AUTRES
				</v-subheader>

				<v-list-item
				v-for="item in itemsPrimary"
				:key="item.title"
				:to="item.to"
				v-on="item.click ? { click: item.click } : null"
				link
				class="m_primary_links"
				:exact-active-class="
					mini ? 'active-class-list-mini' : 'active-class-list'
				"
				>
					<v-tooltip
					v-if="mini"
					right
					nudge-right="20"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-list-item-icon
							class="mr-4"
							v-bind="attrs"
							v-on="on"
							>
								<v-icon
								:class="!mini ? 'm_primary_icon' : ''"
								class="white--text"
								>
									{{ item.icon }}
								</v-icon>
							</v-list-item-icon>
						</template>

						<span>{{ item.title }}</span>
					</v-tooltip>

					<v-list-item-icon
					class="mr-4"
					v-else
					>
						<v-icon
						:class="!mini ? 'm_primary_icon' : ''"
						class="white--text"
						>
							{{ item.icon }}
						</v-icon>
					</v-list-item-icon>

					<v-list-item-content>
						<v-list-item-title class="white--text">
							{{
								item.title
							}}
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>

			<v-btn
			plain
			class="white--text text-center force-bottom"
			:to="{
				name: 'legal-statement'
			}"
			v-if="!mini"
			>
				Mentions légales | CGV | CGU
			</v-btn>

			<template v-slot:append>
				<v-expand-transition>
					<div v-show="showProfile">
						<v-divider/>

						<v-list class="m_profile">
							<v-list-item-group color="primary">
								<v-list-item
								v-if="$hasRight('globalActions.showAccountEditBtn')"
								@click="accountView"
								>
									<v-list-item-icon>
										<v-icon class="white--text">
											mdi-account
										</v-icon>
									</v-list-item-icon>

									<v-list-item-content>
										<v-list-item-title class="white--text">
											Mon profil
										</v-list-item-title>
									</v-list-item-content>
								</v-list-item>

								<v-list-item @click="logout()">
									<v-list-item-icon>
										<v-icon class="white--text">
											mdi-power
										</v-icon>
									</v-list-item-icon>

									<v-list-item-content>
										<v-list-item-title class="white--text">
											Déconnexion
										</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
							</v-list-item-group>
						</v-list>
					</div>
				</v-expand-transition>

				<div class="m_profile tw-w-full tw-overflow-hidden tw-flex tw-gap-[15px] tw-pl-[8px] tw-bg-[#412420] tw-h-[72px] tw-items-center">
					<div>
						<v-img
						@click="if(mini)showProfile = !showProfile"
						:src="imageLink"
						class="tw-h-[40px] tw-w-[40px] tw-rounded-full"
						/>
					</div>

					<div
					class="tw-grow tw-overflow-hidden tw-flex tw-flex-col"
					>
						<div class="tw-whitespace-nowrap tw-overflow-hidden tw-text-ellipsis tw-w-full tw-font-bold -tw-mb-[4px]">
							{{
								user.firstname
									? user.firstname + " " + user.lastname
									: user.displayName
							}}
						</div>

						<div class="m_grey tw-text-[14px] tw-whitespace-nowrap">
							{{
								$t(user.job) || ""
							}}
						</div>
					</div>

					<v-btn
					v-if="!mini"
					class="m_icon"
					icon
					@click="showProfile = !showProfile"
					>
						<v-icon
						color="white"
						>
							{{
								showProfile ? "mdi-chevron-down" : "mdi-chevron-up"
							}}
						</v-icon>
					</v-btn>
				</div>
			</template>
		</v-navigation-drawer>

		<div
		v-if="!mini"
		@click="mini = !mini"
		class="tw-fixed tw-h-full tw-w-full tw-bg-[rgba(0,0,0,0.5)] tw-top-0 tw-left-0 tw-z-10"
		/>

		<v-main style="padding-left: 56px">
			<v-banner
			two-line
			class="red lighten-2"
			v-if="user.impersonated"
			>
				<v-avatar
				slot="icon"
				color="red darken-2"
				size="40"
				>
					<v-icon
					icon="mdi-lock"
					color="white"
					>
						mdi-alert
					</v-icon>
				</v-avatar>

				Attention ! Vous êtes connecté en tant que
				<b>{{ user.firstname + " " + user.lastname }}</b>

				<template v-slot:actions>
					<v-btn
					text
					@click="disconnectFromImpersonated()"
					color="deep-purple accent-4"
					>
						Déconnexion
					</v-btn>
				</template>
			</v-banner>

			<Loader
			color="white"
			:size="200"
			:width="10"
			/>

			<router-view/>

			<DownloadTab/>
		</v-main>

		<SupportModal ref="modal"/>
	</div>
</template>

<script>
import auth from "@/service/auth";
import SupportModal from "../components/SupportModal";

export default {
	name: "ConnectedLayout",
	components: {
		SupportModal
	},
	data(){
		return {
			previousNotifCount: 0,
			warnClass: false,
			user: {},
			drawer: null,
			displayFolderMenu: false,
			displayFolderMenuRoutes: [
				"coring-campaign",
				"campaign-files",
				"campaign-deliverables",
				"coring-campaign-dashboard",
				"coring-campaign-map",
				"auscultation-campaign-dashboard",
				"auscultation-campaign-map",
				"structural-coring-campaign-dashboard",
				"coring-campaign-prestations"
			],
			mini: true,
			showProfile: false,
			userOrganization: {
				id: ""
			},
			profilePictureLink: null
		};
	},
	computed: {
		notificationsCount(){
			return this.$store.state.notifications.countUnread;
		},
		notifIconClass(){
			let notifClass = "m_primary_icon";

			if(false === this.mini) notifClass += " m_primary_icon";
			if(this.warnClass) notifClass += " warnAnimation";

			return notifClass;
		},
		imageLink(){
			if(null === this.profilePictureLink) return (
				"https://eu.ui-avatars.com/api/?background=random&name=" +
          this.user.firstname +
          "+" +
          this.user.lastname
			);
			else return this.profilePictureLink;
		},
		itemsPrimary(){
			let items = [];
			if({} === this.user) return items;
			if(this.$hasRight("organizations.manageAll")){
				items.push({
					title: "Paramètres",
					icon: "mdi-cog",
					to: {name: "organizations-administration"},
					click: () => {
						this.mini = true;
					}
				});
			}
			else if(this.$hasRight("organizations.manageOne")){
				items.push({
					title: "Paramètres",
					icon: "mdi-cog",
					to: {
						name: "organization-administration",
						params: {
							id: this.user.organizationId
						}
					},
					click: () => {
						this.mini = true;
					}
				});
			}
			if(this.$hasRight("globalActions.showStatistics")){
				items.push({
					title: "Statistiques",
					icon: "mdi-chart-areaspline",
					to: {name: "statistics"},
					click: () => {
						this.mini = true;
					}
				});
			}
			if(this.$hasRight("globalActions.showSupportBtn")) items.push({
				title: "Support",
				icon: "mdi-help",
				click: this.showModal
			});
			return items;
		},
		projectItems(){
			let items = [];
			if({} === this.user) return items;
			if(this.$hasRight("globalActions.showProjectsBtn")) items.push({
				title: "En cours",
				icon: "mdi-archive-star-outline",
				to: {
					name: "workspaces"
				},
				click: () => {
					this.mini = true;
				}
			});
			if(this.$hasRight("globalActions.showArchivedProjectsBtn")) items.push({
				title: "Archivés",
				icon: "mdi-archive-check-outline",
				to: {
					name: "workspaces",
					params: {
						archived: "archived"
					}
				},
				click: () => {
					this.mini = true;
				}
			});
			return items;
		}
	},
	watch: {
		notificationsCount(){
			if(this.notificationsCount > this.previousNotifCount){
				this.warnClass = true;
				setTimeout(() => {
					this.warnClass = false;
				}, 4000);
			}
			this.previousNotifCount = this.notificationsCount;
		},
		$route(to){
			this.showFolderMenu(to);
		}
	},
	methods: {
		redirectDeliverables(){
			if(this.$route.params.id === undefined){
				this.$router.push({
					name: "campaigns-files-list"
				});
			}
			else {
				this.$router.push({
					name: "campaign-deliverables",
					params: {id: this.$route.params.id}
				});
			}
			
		},
		openNotifications(){
			this.$root.$emit("openNotifications", this.mini);
		},
		showModal(){
			this.$refs.modal.showModal();
		},
		logout(){
			auth.logout();
			this.$router.push({name: "login"});
		},
		accountView(){
			this.$router.push({name: "profile"});
		},
		disconnectFromImpersonated(){
			localStorage.setItem("token", localStorage.getItem("lastToken"));
			localStorage.removeItem("lastToken");
			document.location.href = "/super-login";
		},
		async showFolderMenu(route){
			if(this.displayFolderMenuRoutes.includes(route.name) === true){
				const documentStats = await this.$api.campaigns.getDocumentsStats(
					route.params.id
				);

				this.$store.commit(
					"filesNotifications/updateFilesCount",
					documentStats.pendingDocuments
				);
				this.$store.commit(
					"filesNotifications/updateLivreriesCount",
					documentStats.last2DaysDeliverables
				);
				this.displayFolderMenu = true;
			}
			else {
				this.displayFolderMenu = false;
			}
		}
	},
	async mounted(){
		this.showFolderMenu(this.$route);
		let user = {};
		if(auth.isLogged()){
			this.user = auth.decodeToken();
			user = await this.$api.users.findById(this.user.userId);
			const path = user.profilePicturePath;
			this.user = {
				...this.user,
				isInternal: user.isInternal,
				organizationId: user.sites[0].organization.id,
				organizationName: user.sites[0].organization.name
			};

			if(null !== path && undefined !== path){
				const doc = await this.$api.documents.download(path);
				this.profilePictureLink = window.URL.createObjectURL(doc);
			}
		}
		else if(auth.isLoggedGuest()){
			user = await this.$api.guests.getByToken(auth.getGuestToken());
			this.user = {
				...user,
				firstname: this.user.displayName,
				userId: this.user.id,
				job: "",
				isInternal: false
			};
			this.$router.push({
				name: user.campaign.type + "-campaign-dashboard-guests",
				params: {
					id: user.campaign.id
				}
			});
		}

		this.$store.commit("user/setUser", this.user);
	}
};
</script>

<style lang="scss">
@import "../assets/scss/_variables.scss";

@keyframes warn {
  0% {
    color: white;
  }
  10% {
    color: white;
    transform: scale(1.2);
  }
  20% {
    color: rgb(170, 21, 21);
    transform: scale(1.2);
  }
  30% {
    color: white;
    transform: scale(1.2);
  }
  40% {
    color: rgb(170, 21, 21);
    transform: scale(1.2);
  }
  50% {
    color: white;
    transform: scale(1.2);
  }
  60% {
    color: rgb(170, 21, 21);
    transform: scale(1.2);
  }
  70% {
    color: white;
    transform: scale(1.2);
  }
  80% {
    color: white;
    transform: scale(1);
  }
  100% {
    color: white;
    transform: scale(1);
  }
}

.v-navigation-drawer--mini-variant > div > button {
  right: 10px !important;
}

.m_navigation {
  background-color: $dark !important;
  color: #fff !important;
  position: fixed !important;

  .v-icon {
    width: 37px;
    margin-right: 16px;
  }

  .m_retract_icon {
    float: right;
    top: 5px;
    margin-right: 0;
  }
  .m_expand_icon {
    top: 5px;
  }

  .m_icon {
    color: #ed7507 !important;

    i {
      font-size: 40px !important;
    }
  }

  .m_primary_links {
    font-family: "Open Sans", sans-serif;

    .m_primary_icon {
      background-color: transparent;
      color: white;
      border-radius: 50px;
      height: 37px;
      width: 37px;
    }

    .warnAnimation {
      animation-name: warn;
      animation-duration: 4s;
    }

    div {
      font-size: 15px !important;
      font-weight: 300 !important;
      line-height: 2rem !important;
    }
  }

  .v-list--dense .v-subheader {
    font-size: 15px;
  }

  .v-list--dense .v-list-item .v-list-item__title {
    font-size: 15px;
    font-weight: normal !important;
  }

  .v-list-item {
    &__icon {
      margin-right: 0 !important;
    }
  }

  .v-list {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
}

.v-item-group {
  background-color: inherit;
}

.m_profile {
  color: #fff !important;
  background-color: #412420 !important;

  .m_grey {
    color: $grey !important;
  }
}

.logo {
  width: 100%;
  margin-top: -20px;
}

.mdi-chevron-right,
.mdi-chevron-left {
  margin-right: 0 !important;
}

.force-bottom {
  position: absolute !important;
  bottom: 80px !important;
  width: 100% !important;
  font-size: 10px !important;
}

.logo-div {
  height: 40px;
  width: 100%;
  text-align: center;
  .v-icon {
    margin-right: 0px;
  }
}

.icon-height {
  height: 40px;
}
.no-height {
  height: 0;
}
.ml-2-5 {
  margin-left: 10px;
}

.active-class-list {
  background-color: white;
  margin-right: 20px;
  border-radius: 0 4px 4px 0;
}

.active-class-list-mini {
  background-color: white;
  margin-right: 5px;
  border-radius: 0 4px 4px 0;
}

.active-class-list > div.v-list-item__icon > i.v-icon,
.active-class-list-mini > div.v-list-item__icon > i.v-icon {
  color: #412420 !important;
}

.active-class-list > div.v-list-item__content > div.v-list-item__title,
.active-class-list-mini > div.v-list-item__content > div.v-list-item__title {
  color: #412420 !important;
}

.active-class-list > div.v-list-item__content > div.v-list-item__title > span {
  background-color: #412420;
  color: white;
}

.v-main {
  height: 100vh;
}

.m_textarea textarea {
  margin-bottom: 5px;
  margin-right: 7px;
}

#connected {
  display: flex;
}
.scrollable_container {
  overflow-y: auto;
  height: inherit;
}
.v-application .primary--text {
  color: $brown !important;
  caret-color: $brown !important;
}
</style>
