import {api} from "../../api/index";

export default {
	namespaced: true,
	strict: false,
	state: {
		downloading: false,
		downloadList: [],
		mergePdfList: [], 
		selecting: false,
		downloadLinkModalOpen: false
	},

	mutations: {
		SET_DOWNLOADING(state, downloading){
			state.downloading = downloading;
		},
		SET_DOWNLOAD_LIST(state, downloadList){
			state.downloadList = downloadList;
		},
		DELETE_FROM_DOWNLOAD_LIST(state, path){
			state.downloadList = state.downloadList.filter(e => e.name !== path);
		},
		SET_DOWNLOAD_STATUS(state, object){
			let element = state.downloadList.find(e => e.name === object.element && e.downloadState !== "finished");
			element.downloadState = object.downloadState;
			let index = state.downloadList.indexOf(e => e === element);

			state.downloadList[index = element];
		},
		DELETE_FROM_MERGE_PDF_LIST(state, object){
			state.mergePdfList = state.mergePdfList.filter(e => e.id !== object.id);
		},
		ADD_MERGE_PDF_LIST(state, object){
			state.mergePdfList = [...state.mergePdfList, {id: object.id, path: object.path}];
		},
		CLEAR_MERGE_PDF_LIST(state){
			state.mergePdfList = [];
			state.selecting = false;
		},
		SET_SELECTING(state, selecting){
			state.selecting = selecting;
		},
		SET_DOWNLOAD_LINK_MODAL_OPEN(state, value){
			state.downloadLinkModalOpen = value;
		},
		MOVE_UP(state, index){
			if(index > 0){
			  const temp = state.mergePdfList[index];
			  state.mergePdfList.splice(index, 1);
			  state.mergePdfList.splice(index - 1, 0, temp);
			}
		},
		MOVE_DOWN(state, index){
			if(index < state.mergePdfList.length - 1){
			  const temp = state.mergePdfList[index];
			  state.mergePdfList.splice(index, 1);
			  state.mergePdfList.splice(index + 1, 0, temp);
			}
		},
		REMOVE_ITEM_MERGE_PDF_LIST(state, index){
			state.mergePdfList.splice(index, 1);
		  },
	},

	getters: {
		downloading(state){
			return state.downloading;
		},
		downloadList(state){
			return state.downloadList;
		},
		mergePdfList(state){
			return state.mergePdfList;
		},
		selecting(state){
			return state.selecting;
		},
		downloadLinkModalOpen(state){
			return state.downloadLinkModalOpen;
		}
	},

	actions: {
		async initStore({dispatch}){
			await Promise.all([
				dispatch("setDownloading", false),
				dispatch("setDownloadList", [])
			]);
		},

		purgeStore({commit}){
			commit("SET_DOWNLOADING", false);
			commit("SET_DOWNLOAD_LIST", false);
		},

		setDownloading({commit}, downloading){
			commit("SET_DOWNLOADING", downloading);
		},

		setDownloadList({commit}, downloadList){
			commit("SET_DOWNLOAD_LIST", downloadList);
		},

		setDownloadStatus({commit}, object){ 
			commit("SET_DOWNLOAD_STATUS", object);
		},

		deleteFromDownloadList({commit}, path){
			commit("DELETE_FROM_DOWNLOAD_LIST", path);
		},

		setSelecting({commit}, selecting){
			commit("SET_SELECTING", selecting);
		},

		moveUp({commit}, index){
			commit("MOVE_UP", index);
		},
		moveDown({commit}, index){
			commit("MOVE_DOWN", index);
		},
		removeItem({commit}, index){
			commit("REMOVE_ITEM_MERGE_PDF_LIST", index);
		}
	}
};
