import axiosPlugin from "../../plugins/axios";
import toastPlugin from "../../plugins/messages";
const axios = axiosPlugin._axios;
const toast = toastPlugin.toast;
const getMessage = toastPlugin.getMessage;

const findByCampaign = async id => {
	try {
		const response = await axios.get(`/coring-campaigns/${id}/layer-names`);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les noms des couches" + getMessage(error.response.status)
		);
		throw error;
	}
};

const updateByCampaign = async(campaignId, data) => {
	try {
		const response = await axios.put(`/coring-campaigns/${campaignId}/layer-names`, data);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les noms des couches" + getMessage(error.response.status)
		);
		throw error;
	}
};

export default {
	findByCampaign,
	updateByCampaign
};
