import axiosPlugin from "../../plugins/axios";
import toastPlugin from "../../plugins/messages";
const axios = axiosPlugin._axios;
const toast = toastPlugin.toast;
const getMessage = toastPlugin.getMessage;

const findAll = async(organizationId = null) => {
	try {
		let route = "/material-categories";
		if(organizationId !== null){
			route += `?organizationId=${organizationId}`;
		}
		const response = await axios.get(route, {
			showLoader: false
		});
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les catégories de matériaux" +
        getMessage(error.response.status)
		);
		throw error;
	}
};

export default {
	findAll
};
